export const routes = {
  // API routes are routes that don't display anything but can be hit by
  // the browser or other clients to get and post data.
  api: {},

  dashboard: '/',
  onboarding: '/onboarding',

  /*///////////////////////////////
  /////// Project routes //////////
  ///////////////////////////////*/
  projects: (orgSlug: string) => `/${orgSlug}/projects`,
  projectsFolder: (orgSlug: string, folderId: string) =>
    `/${orgSlug}/projects?folderId=${folderId}`,
  projectCreate: (orgSlug: string, folderPath?: string) =>
    !folderPath ?
      `/${orgSlug}/projects/create`
    : `/${orgSlug}/projects/create?folderPath=${folderPath}`,
  projectUseTemplate: (orgSlug: string, projectTemplateId?: string) => {
    return projectTemplateId ?
        `/${orgSlug}/projects/use-template?projectTemplateId=${projectTemplateId}`
      : `/${orgSlug}/projects/use-template`
  },

  projectSingle: (orgSlug: string, projectId: string) => `/${orgSlug}/projects/${projectId}`,
  projectSingleTask: (orgSlug: string, projectId: string, taskId: string) =>
    `/${orgSlug}/projects/${projectId}/${taskId}`,
  projectSingleTaskEdit: (orgSlug: string, projectId: string, taskId: string) =>
    `/${orgSlug}/projects/${projectId}/${taskId}/edit`,
  projectSingleActivity: (orgSlug: string, projectId: string) =>
    `/${orgSlug}/projects/${projectId}/activity`,
  projectSingleRecipients: (orgSlug: string, projectId: string) =>
    `/${orgSlug}/projects/${projectId}/recipients`,
  projectSingleSettings: (orgSlug: string, projectId: string) =>
    `/${orgSlug}/projects/${projectId}/settings`,

  /*///////////////////////////////
  /////// Template routes /////////
  ///////////////////////////////*/
  projectTemplates: (orgSlug: string) => `/${orgSlug}/project-templates`,
  projectTemplateEdit: (orgSlug: string, projectTemplateId: string) =>
    `/${orgSlug}/project-templates/${projectTemplateId}`,
  projectTemplateTaskEdit: (orgSlug: string, projectTemplateId: string, taskId: string) =>
    `/${orgSlug}/project-templates/${projectTemplateId}/${taskId}`,
  taskTemplates: (orgSlug: string) => `/${orgSlug}/task-templates`,
  taskTemplateEdit: (orgSlug: string, taskTemplateId: string) =>
    `/${orgSlug}/task-templates/${taskTemplateId}`,

  /*///////////////////////////////
  /////// Organization routes /////
  ///////////////////////////////*/

  /*///////////////////////////////
  /////// Org routes //////////
  ///////////////////////////////*/
  organizationDashboard: (orgSlug: string) => `/${orgSlug}`,
  organizationActivity: (orgSlug: string) => `/${orgSlug}/activity`,
  organizationTrash: (orgSlug: string) => `/${orgSlug}/trash`,

  /*///////////////////////////////
  /////// Settings //////////
  ///////////////////////////////*/
  settings: '/settings',
  settingsProfile: '/settings/profile',
  settingsLoginSecurity: '/settings/login-security',

  organizationSettings: (orgSlug: string) => `/settings/${orgSlug}`,
  organizationColorTheme: (orgSlug: string) => `/settings/${orgSlug}/color-theme`,
  organizationCustomBranding: (orgSlug: string) => `/settings/${orgSlug}/branding`,
  organizationMembers: (orgSlug: string) => `/settings/${orgSlug}/members`,
  organizationMemberSingle: (orgSlug: string, memberId: string) =>
    `/settings/${orgSlug}/members/member/${memberId}`,
  organizationInviteSingle: (orgSlug: string, inviteId: string) =>
    `/settings/${orgSlug}/members/invite/${inviteId}`,
  organizationGroups: (orgSlug: string) => `/settings/${orgSlug}/groups`,
  organizationGroupSingle: (orgSlug: string, groupId: string) =>
    `/settings/${orgSlug}/groups/${groupId}`,
  organizationRecipients: (orgSlug: string) => `/settings/${orgSlug}/recipients`,
  organizationRecipientSingle: (orgSlug: string, recipientId: string) =>
    `/settings/${orgSlug}/recipients/recipient/${recipientId}`,

  respond: {
    dashboard: (magicKey: string) => `/respond/${magicKey}`,
    task: (magicKey: string, taskId: string, redirectBecauseSingleTask?: boolean) => {
      if (redirectBecauseSingleTask) return `/respond/${magicKey}/${taskId}?redirect=single-task`
      return `/respond/${magicKey}/${taskId}`
    },
  },

  // app disclaimer links
  privacyPolicy: 'https://rooquest.com/privacy-policy',
  termsOfUse: 'https://rooquest.com/terms-of-use',

  // authentication
  login: (
    params: { email?: string | null; inviteKey?: string | null; from?: string | null } = {}
  ) => {
    const searchParams = new URLSearchParams()
    if (params.email) searchParams.set('email', params.email)
    if (params.inviteKey) searchParams.set('inviteKey', params.inviteKey)
    if (params.from) searchParams.set('from', params.from)

    return searchParams.size > 0 ? '/login?' + searchParams.toString() : '/login'
  },
  logout: '/logout',
  register: (params: { email?: string; inviteKey?: string | null } = {}) => {
    const searchParams = new URLSearchParams()
    if (params.email) searchParams.set('email', params.email)
    if (params.inviteKey) searchParams.set('inviteKey', params.inviteKey)
    return searchParams.size > 0 ? '/register?' + searchParams.toString() : '/register'
  },
  forgotPassword: (email: string) =>
    '/forgot-password' + (email ? `?email=${encodeURIComponent(email)}` : ''),
}
